import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import InformationLayout from "../../layouts/InformationLayout.jsx";
import indexData from "./index.json";
import Downloads from "../../components/_ui/Downloads.jsx";
import InlineImageGallery from "../../components/InlineImageGallery.jsx";
export const _frontmatter = {
  "title": "Pergola Planning - General information about our Pergolas"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};

const MDXLayout = ({
  children,
  pagesList
}) => <InformationLayout pagesList={indexData.pageIndex}>
    {children}
  </InformationLayout>;

export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`General information about our Pergolas`}</h1>
    <img src="/img/pergola-planning/general-information-about-our-pergolas/fig-25.jpg" alt="A hand-drawn illustration of a lean-to pergola" />
    <p><strong parentName="p">{`The wood that we use for our pergolas is cut, trimmed and finished ready for you to assemble straight away. The softwood is also treated and has a 10 year guarantee. The wood comes from responsible certified sources and is audited and approved at the Mill.`}</strong></p>
    <h2>{`Wood types`}</h2>
    <h4>{`1) Hardwood`}</h4>
    <p>{`The oak we use is very long lasting and weathers to a natural classic appearance that complements your existing home and garden beautifully. This is a brilliant material to use where a strong architectural look is required and will give you years of life and pleasure. Please be aware that 'splits and cracks' may appear in this timber as part of the drying process. This is entirely natural and does not harm the wood, or its structural properties, in any way but gives it a distinctive character that makes it unique. Please see pictures below`}{`*`}{` highlighting this process so that you are fully informed if your oak lengths are delivered with these normal characteristics or if they develop a few few weeks or months after assembly. Also, depending on the sap levels natuarally present in the oak, black marks may appear as a result of the friction between the circular saw and the timber itself which causes the sap to burn. As our Saw Mill cuts to order it is impossible to tell whether this reaction will occur prior to cutting - as not all the oak is affected. Please see the picture below which is the worst scenario that we have seen. These marks will fade as the characteristics of the timber 'weather-in' and age and in no way alters the longevity or structural integrity of the oak`}</p>
    <p><strong parentName="p">{`Hardwood Characteristics: -`}</strong></p>
    <InlineImageGallery images={[{
      src: "/img/pergola-planning/general-information-about-our-pergolas/oak2.jpg",
      alt: "Oak Two"
    }, {
      src: "/img/pergola-planning/general-information-about-our-pergolas/oak1.jpg",
      alt: "Oak One"
    }, {
      src: "/img/pergola-planning/general-information-about-our-pergolas/oak3.jpg",
      alt: "Oak Three"
    }]} mdxType="InlineImageGallery" />
    <p>{`Also, it is impossible to predict the levels of sap in a piece of timber as every living organism is different. If there is a high level (which is obviously finite) the tannins can squeeze out of the micro pores of the timber until it dries and ‘weathers-in’. Particularly it seems when it has rained - as the water eases the osmosis process.`}</p>
    <p>{`We would recommend that you do not leave the oak timber on a patio as the tannins can sometimes leek out during rain and could lead to it being stained. It is best to leave it on earth, gravel, grass or non-visible hand-standing before you assemble it. If you want to seal the oak we recommend the below product from Osmo.`}</p>
    <p>{`The Osmo product is a UV protected liquid wax which, in effect, when applied blocks the micro pores of the timber thus not allowing any sap to escape. See their description of the product below.`}</p>
    <p>{`a liquid wax with UV protection can be applied. The wax simply sinks into the oak and is not visible to the naked eye.`}</p>
    <p>{`Here is a link to their website:`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.osmouk.com/sitechaptern.cfm?bookid=Products&chapter=82&page=262#UVProtectionOil"
      }}>{`https://www.osmouk.com/sitechaptern.cfm?bookid=Products&chapter=82&page=262#UVProtectionOil`}</a></p>
    <p>{`Once the Osmo oil is applied there should be no more seepage. As tannin is a natural element (ie: not a man-made chemical) any stain will disappear over a few weeks as the weather elements take effect.`}</p>
    <p>{`If you want to get rid of any stain before the natural erosion takes place, please find this explanatory video link below which advises you on the best method to use:`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.jacksonslandscapedesign.com/tannin-stain-removal-from-sawn-sandstone/"
      }}>{`https://www.jacksonslandscapedesign.com/tannin-stain-removal-from-sawn-sandstone/`}</a></p>
    <h4>{`2) Softwood`}</h4>
    <p>{`All our softwood undergoes a pressure - treated process allowing us to offer a 10 year guarantee. Please bear in mind that if you do grow climbing plants or vines to cover the pergola a lot of the wood will not be exposed during the spring and summer months due to the foliage. The softwood can be specified in either ‘rough sawn’ or ‘planed & chamfered’ - depending on the look you want to achieve. It is worth noting that the rough sawn timber does have edges that are prone to splinters so care must be taken when handling it and when young children are around. The 'planed & chamfered' finish has the edges rounded off and a smooth overall feel.`}</p>
    <h2>{`Wood sizes`}</h2>
    <p><strong parentName="p">{`a) Posts`}</strong>{` – in standard form these come in 100mm x 100mm x 2400mm (approx: 4ins by 4ins by 7ft 8ins) sections. However, we are able to increase the length of the posts up to a maximum length of 2.7mtrs (approx: 8ft 8ins) to allow for the differences in window/door heights - particularly in older houses. After you have measured up where you would like your pergola to go and find that you need a couple of extra centimetres or so - please email us when you order and we will ensure that your requirements are sent to the Mill. There is no extra charge for post lengths up to 2.7mtrs. The 100mm width gives maximum structural support whilst not being too unwieldy whilst the @ 8ft headroom is ample for most people’s requirements. They have been designed to withstand most of the harsher elements of our British weather and are strong enough to carry a full coverage of climbing plants, snow and ice. One end of these posts will be ‘notched’ and pre-drilled to allow the fitting of the main beam.`}</p>
    <p><strong parentName="p">{`b) Cross Rafters`}</strong>{` – supplied in 38mm x 125mm x 2700mm (approx: 1.5 inch by 5 inch by 9 foot) sections. The 125mm depth of these rafters prevents sagging and again gives support not only to the structure but the climbing plants as well. Please be aware that although the length of these rafters is 2700mm, the actual depth of your pergola will only be 2400mm. This is because we finish one end of the rafter with a decorative design that overhangs the main outer support beam giving a typical pergola feature that completes the picture. The cross rafters should be spaced at 680mm intervals ensuring sufficient support for climbing plants to cover the roof of your pergola.`}</p>
    <p><strong parentName="p">{`c) Ledger Board`}</strong>{` – this is the main inner support beam that is attached to the wall and, from which, the unfinished ends of the cross rafters are fixed to using the hanging joists. Again, these are supplied in 38mm x 125mm 2700mm (approx: 1.5 inch by 5 inch by 9 foot) sections. If you order our basic 9ft x 9ft module, the single ledger board will have decorated ends to match the ones on the cross rafters. When ordering our 9ft x 16ft module only one end of each ledger board will have a decorated end (cut to a length of 2430mm) allowing the 2 unfinished ends to butt up to each other giving a seemingly continuous line. The 9ft x 23ft module has 2 x 2430mm ledger boards (decorated as above) and an extension board (cut to 2160mm) which has no decorated ends, again allowing you to butt these against each other for the same continuous look.`}</p>
    <p><strong parentName="p">{`d) Main Outer Support Beam`}</strong>{` – exactly the same dimensions as the Ledger Board and ties together the posts and cross rafters completing the structure. Depending on the module ordered the Main Beam follows exactly the same principle as the Ledger Board in terms of decorative ends and quantities. The Main Outer Support Beam comes pre-drilled to allow the coach bolts provided to attach it to the posts.`}</p>
    <p><strong parentName="p">{`e) Strengthening Support Struts`}</strong>{` – cut at a 45 degree angle at either end and measuring 38mm x 125mm x 600mm (approx: 1.5 inch by 5 inch by 18 inch) these braces when screwed to the post and main outer beam finish off the pergola by giving it the necessary rigidity.`}</p>
    <h2>{`Downloads & Support`}</h2>
    <Downloads files={[{
      filename: "Pergola assembly guide",
      path: "/downloads/ASSEMBLY.pdf"
    }, {
      filename: "Pergola wood treatment information",
      path: "/downloads/EN-Wolmanit_CX_enduser_022010.pdf"
    }]} mdxType="Downloads" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      